import _ from "lodash";
import moment from "moment";
import {
  TYPE_CGC_CPF,
  TYPE_TIMESTAMP,
  TYPE_PERSON,
  TYPE_CURRENCY,
  TYPE_CEP,
  TYPE_PHONE,
} from "./schema";
import {
  PESSOA_FISICA,
  ADMIN,
  USER,
  NIVEL1,
  NIVEL2,
  FILTER_CITY,
  FILTER_PRODUCT,
  FILTER_INSTRUMENT,
  FILTER_SECTOR,
  FILTER_PORT,
  FILTER_REGIONAL_UNIT,
  FILTER_YEAR,
  FILTER_CONTACT_TYPE,
  FILTER_THEME,
  FILTER_HAS_ACTIVE_EVENTS,
  RECSYS,
  HISTORIC,
  SIMILARITY,
  PRODUCT,
  MARKET,
  STATE_NAMES,
  XLS,
  CSV
} from "../constants";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";
import XLSX from "xlsx"

export function urlParam(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join("&");
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}

export function formatKNumber(num) {
  if (Math.abs(num) > 999 && Math.abs(num) < 999999) {
    return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k";
  } else if (Math.abs(num) > 999999) {
    return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "kk";
  }

  return Math.sign(num) * Math.abs(num);
}

export function exportFile(header, rows, filename, file_format) {
  switch (file_format) {
    case XLS:
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet([])

      XLSX.utils.sheet_add_aoa(ws, [header]);
      XLSX.utils.sheet_add_json(ws, rows, { origin: 'A2', skipHeader: true });
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      
      XLSX.writeFile(wb, `${filename}.xlsx`);
      break;
  
    case CSV:
      const timestampFormat = "DD_MM_YYYY_HH_MM_SS";
      const outFilename = `${filename}_${moment().format(timestampFormat)}.csv`;

      let parsedHeader = header.join(";");
      let csvContent = parsedHeader + "\r\n";

      rows.forEach((row) => {
        const rowParsed = _.values(row).join(";");
        csvContent += rowParsed + "\r\n";
      });

      const blob = new Blob(
        [
          //UTF-8 BOM does the trick for Excel
          new Uint8Array([0xef, 0xbb, 0xbf]),
          csvContent,
        ],
        { encoding: "UTF-8", type: "text/csv;charset=UTF-8" }
      );

      const url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", outFilename);
      document.body.appendChild(link);
      link.click();
      break;
      
    default:
      break;
  }
}

export function getColorLegendByDensity(key) {
  const colors = {
    0: "#D6EBFF",
    1: "#85C3FF",
    2: "#52AAFF",
    3: "#0078EB",
    4: "#005EB8",
  };

  return colors[~~key] || "#E8E8E8";
}

export const cpfCnpjMask = (value) => {
  let diff = 0;
  let prefix = "";
  let cnpjCpf = null;

  if (!value) return;

  cnpjCpf = value.toString().replace(/\D/g, "");

  if (cnpjCpf.length === 13) {
    cnpjCpf = `0${cnpjCpf}`;
  }

  if (cnpjCpf.length < 11) {
    diff = 11 - cnpjCpf.length;

    for (let i = 0; i < diff; i++) {
      prefix += "0";
    }

    cnpjCpf = `${prefix}${cnpjCpf}`;
  }

  if (cnpjCpf.length === 11) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  );
};

export const cepMask = (value) => {
  const cep = value.toString().trim().replace(/\D/g, "");

  return cep.replace(/(\d{5})(\d{3})/g, "$1-$2");
};

export const phoneMask = (value) => {
  let phone = value.toString().replace(/\D/g, "");

  if (phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
  }

  return phone.replace(/(\d{2})(\d{5})(\d{4})/g, "($1) $2-$3");
};

export const fieldParser = (value, type) => {
  if (type === TYPE_CGC_CPF) {
    return cpfCnpjMask(value) || "N/A";
  }

  if (type === TYPE_TIMESTAMP) {
    if (value)
      return (
        new Intl.DateTimeFormat("pt-BR", { timeZone: "GMT" }).format(value) ||
        "N/A"
      );
    return "N/A";
  }

  if (type === TYPE_PHONE) {
    return phoneMask(value);
  }

  if (type === TYPE_CEP) {
    return cepMask(value);
  }

  if (type === TYPE_PERSON) {
    value = value.trim().toUpperCase();
    return value === PESSOA_FISICA ? "Física" : "Jurídica";
  }

  if (type === TYPE_CURRENCY) {
    return `R$ ${formatMoney(value)}`;
  }

  return value == null ? "N/A" : value;
};

export function getGeoJson({ data, featuresById }) {
  let newFeatures;
  let newData = {
    type: "FeatureCollection",
    features: [],
  };

  newFeatures = _.cloneDeep(featuresById);

  Object.keys(newFeatures).forEach((key) => {
    let density = -1;

    if (data.data_by_key[key]) {
      density = data.data_by_key[key].density;

      if (data.density) {
        density = data.density;
      }
    }

    newFeatures[key].properties.density = density;
  });

  Object.keys(newFeatures).forEach((key) => {
    newData.features.push(newFeatures[key]);
  });

  return newData;
}

export const downloadFromURL = (url) => {
  const link = document.createElement("a");

  link.href = url;
  link.setAttribute("download", "file.csv");
  document.body.appendChild(link);
  link.click();
};

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const getDateYYMMDDDD = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return `${year}-${month}-${dt}`;
};

export const getUserData = async () => {
  let data = {};
  let role;
  let groups = [];
  let tenant = null;
  let adminList = [];
  let userN1List = [];
  let userN2List = [];
  let tenantList = [];
  let session = null;

  try {
    session = await Auth.currentSession();
    groups = session.idToken.payload["cognito:groups"];

    if (groups.length) {
      adminList = groups.filter(
        (group) => group.trim().toUpperCase() === ADMIN
      );
      userN1List = groups.filter(
        (group) => group.trim().toUpperCase() === NIVEL1
      );
      userN2List = groups.filter(
        (group) => group.trim().toUpperCase() === NIVEL2
      );
      tenantList = groups
        .filter((group) => group.trim().toUpperCase() !== ADMIN)
        .filter((group) => group.trim().toUpperCase() !== NIVEL1)
        .filter((group) => group.trim().toUpperCase() !== NIVEL2);
    }

    if (tenantList && tenantList.length) {
      tenant = tenantList[0];
    }

    adminList && adminList.length
      ? (role = ADMIN)
      : userN1List && userN1List.length
      ? (role = NIVEL1)
      : userN2List && userN2List.length
      ? (role = NIVEL2)
      : (role = USER);

    if (tenant) {
      tenant = tenant
        .toString()
        .replace(`${process.env.REACT_APP_USERPOOLID}_`, "");
    }

    data = {
      name: session.idToken.payload.name,
      email: session.idToken.payload.email,
      tenant,
      role,
      state_name: STATE_NAMES[tenant]
    };
  } catch (e) {
    console.error("[getUserData] Error", e);
  }

  return data;
};

export const isValidEmailDomain = (email, tenant) => {
  let validEmailDomain = "@" + tenant.substr(6, 2) + ".sebrae.com.br";
  return email.substring(email.length, email.indexOf("@")) !== validEmailDomain
    ? true
    : false;
};

export const isValidRangeDate = (dateFrom, dateTo) => {
  let isValidRange = false;
  let date = new Date();

  date.setDate(date.getDate() + 0);

  if ((dateFrom !== null && dateTo !== null) && (dateFrom < dateTo && (dateFrom < date || dateTo < date))) {
    isValidRange = true;
  }

  return isValidRange;
};

export const getDefaultDate = ({ delta }) => {
  let date = new Date();

  date.setDate(date.getDate() + delta || 0);

  return date;
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });

  export async function captureSentryException(err, filter, context="Sagas Error"){
    let userData = {};
    let scope = new Sentry.Scope();

    userData = await getUserData();

    scope.setExtra("Error message", err.message);
    scope.setExtra("User Data", userData);
    scope.setExtra("Filter", filter);

    if (err.config) {
      Sentry.captureException(new Error(err.config.baseURL + "/" + err.config.url),() => scope);
    } else {
      Sentry.captureException(new Error(context), () => scope);
    }

  }

export const getFilterValues = (filter)=>{
  return filter?.length? filter.map((item) => item.value): null
}

export const getAppClientFilterOptions = ()=>{
  return [
    { label: "Sim", value: true },
    { label: "Não", value: false },
    { label: "Todos", value: null },
  ]
}

export const getFilterRules = (component_props, context, chip, name=null) => {
  let filters = [];
  const functions = {
    [FILTER_PRODUCT]: (filter) => component_props.getProducts(filter),
    [FILTER_REGIONAL_UNIT]: (filter) => component_props.getRegionalUnits(filter),
    [FILTER_CITY]: (filter) => component_props.getCities(filter),
    [FILTER_SECTOR]: (filter) => component_props.getSectors(filter),
    [FILTER_PORT]: (filter) => component_props.getPorts(filter),
    [FILTER_INSTRUMENT]: (filter) => component_props.getInstruments(filter),
    [FILTER_YEAR]: (filter) => component_props.getYears(filter),
    [FILTER_CONTACT_TYPE]: (filter) => component_props.getContactTypes(filter),
    [FILTER_THEME]: (filter) => component_props.getThemes(filter),
    [FILTER_HAS_ACTIVE_EVENTS]: (filter) => component_props.getHasActiveEvents(filter),
  };

  if (chip === HISTORIC) {
    filters = [
      FILTER_PRODUCT,
      FILTER_CITY,
      FILTER_SECTOR,
      FILTER_PORT,
      FILTER_REGIONAL_UNIT,
      FILTER_INSTRUMENT,
      FILTER_YEAR,
    ];
  }

  if (context === PRODUCT && (chip === RECSYS || chip === SIMILARITY)) {
    filters = [
      FILTER_PRODUCT,
      FILTER_REGIONAL_UNIT,
      FILTER_CITY,
      FILTER_SECTOR,
      FILTER_PORT,
      FILTER_INSTRUMENT,
      FILTER_CONTACT_TYPE,
      FILTER_THEME,
      FILTER_HAS_ACTIVE_EVENTS
    ];
  }

  if (context === MARKET && chip === RECSYS ) {
    filters = [
      FILTER_PRODUCT,
      FILTER_REGIONAL_UNIT,
      FILTER_CITY,
      FILTER_SECTOR,
      FILTER_PORT,
      FILTER_INSTRUMENT,
      FILTER_THEME
    ];
  }

  let filterFunctions = [];

  for (let filter of filters) {
    if (filter !== name) {
      filterFunctions.push(functions[filter]);
    }
  }

  return filterFunctions;
};
