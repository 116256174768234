import { combineReducers } from "redux";

import map from "./map";
import chip from "./chip";
import city from "./city";
import year from "./year";
import title from "./title";
import filter from "./filter";
import dialog from "./dialog";
import tenant from "./tenant";
import product from "./product";
import customer from "./customer";
import instrument from "./instrument";
import timeline from "./timeline";
import pie_chart from "./pie_chart";
import bar_chart from "./bar_chart";
import treemap_market from "./treemap_market";
import product_detail from "./product_detail";
import customer_detail from "./customer_detail";
import treemap_product from "./treemap_product";
import rec_customer_product from "./rec_customer_product";
import rec_product from "./rec_product";
import rec_market from "./rec_market";
import persona_info from "./persona_info";
import dashboard from "./dashboard";
import sector from "./sector";
import port from "./port";
import rfb_filter from "./rfb_filter";
import rfb_enterprise from "./rfb_enterprise";
import rfb_partner from "./rfb_partner";
import crm from "./crm";
import tenant_config from "./tenant_config";
import user_register from "./user_register";
import rec_customer_event from "./rec_customer_event";
import event from "./event";
import date_event from "./date_event";
import { reducer as toastr } from "react-redux-toastr";
import target_audience from "./target_audience";
import users_groups from "./users_groups";
import user_register_dialog from "./user_register_dialog";
import chatbot from "./chatbot";
import chatbot_customer from "./chatbot_customer";
import rec_customer_content from "./rec_customer_content";
import chatbot_details from "./chatbot_details";
import chatbot_config from "./chatbot_config";
import notify_etl from "./notify_etl";
import theme from "./theme";
import rec_product_similarity from "./rec_product_similarity";
import regional_unit from "./regional_unit";
import github_api from "./github_api";
import rec_product_indicators from "./rec_product_indicators";
import rec_similarity_indicators from "./rec_similarity_indicators";
import contact_type from "./contact_type";
import pub_alvo from "./pub_alvo";
import macrossegment from "./macrossegment";
import target_audience_per_product from "./target_audience_per_product";
import has_active_events from "./has_active_events";

const rootReducer = combineReducers({
  map,
  chip,
  city,
  year,
  title,
  filter,
  dialog,
  toastr,
  tenant,
  product,
  customer,
  instrument,
  timeline,
  pie_chart,
  bar_chart,
  treemap_market,
  product_detail,
  customer_detail,
  treemap_product,
  rec_customer_product,
  rec_product,
  rec_market,
  persona_info,
  dashboard,
  sector,
  port,
  rfb_filter,
  rfb_enterprise,
  rfb_partner,
  crm,
  tenant_config,
  user_register,
  rec_customer_event,
  event,
  date_event,
  target_audience,
  users_groups,
  user_register_dialog,
  chatbot,
  chatbot_customer,
  rec_customer_content,
  chatbot_details,
  chatbot_config,
  notify_etl,
  theme,
  rec_product_similarity,
  regional_unit,
  github_api,
  rec_product_indicators,
  rec_similarity_indicators,
  contact_type,
  pub_alvo,
  macrossegment,
  target_audience_per_product,
  has_active_events,
});

export default rootReducer;
