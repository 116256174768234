import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import { RECSYS, SIMILARITY } from '../../constants';

/* Types & Action Creators */

export const { Types, Creators } = createActions({
    hasActiveEventsRequest: [],
    getHasActiveEvents: ['filter'],
    hasActiveEventsSuccess: ['context', 'data'],
    hasActiveEventsError: ['err']
  });
  
  /* Initial State */
  
  export const INITIAL_STATE = Immutable({
    [RECSYS]: [],
    [SIMILARITY]: [],
    loading: false,
    error: false
  });
  
  /* Reducers */
  
  export const request = state => state.merge({ loading: true });
  
  export const success = (state, { context, data }) => state.merge({ [context]: data, error: false, loading: false });
  
  export const error = state => state.merge({ loading: false, error: true });
  
  /* Reducers to types */
  
  export default createReducer(INITIAL_STATE, {
    [Types.HAS_ACTIVE_EVENTS_REQUEST]: request,
    [Types.HAS_ACTIVE_EVENTS_SUCCESS]: success,
    [Types.HAS_ACTIVE_EVENTS_ERROR]: error
  });