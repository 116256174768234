import { compose } from "recompose";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Creators as TitleCreators } from "../../store/ducks/title";
import { Creators as ProductDetailCreators } from "../../store/ducks/product_detail";
import { Creators as CustomerDetailCreators } from "../../store/ducks/customer_detail";
import { Creators as RegionalUnitCreators } from "../../store/ducks/regional_unit";
import { Creators as FilterCreators } from "../../store/ducks/filter";
import { Creators as CityCreators } from "../../store/ducks/city";
import { Creators as RecProductCreators } from "../../store/ducks/rec_product";
import { Creators as RecProductSimilarityCreators } from "../../store/ducks/rec_product_similarity";
import { Creators as DialogCreators } from "../../store/ducks/dialog";
import { Creators as MapCreators } from "../../store/ducks/map";
import { Creators as ChipCreators } from "../../store/ducks/chip";
import { Creators as TreemapProductCreators } from "../../store/ducks/treemap_product";
import { Creators as TenantConfigCreators } from "../../store/ducks/tenant_config";
import { Creators as ProductCreators } from "../../store/ducks/product";
import { Creators as RecProductIndicatorsCreators } from "../../store/ducks/rec_product_indicators";
import { Creators as ContactTypeCreators } from "../../store/ducks/contact_type";
import { Creators as SectorCreators } from "../../store/ducks/sector";
import { Creators as PortCreators } from "../../store/ducks/port";
import { Creators as InstrumentCreators } from "../../store/ducks/instrument";
import { Creators as YearCreators } from "../../store/ducks/year";
import { Creators as ThemeCreators } from "../../store/ducks/theme";
import { Creators as HasActiveEventsCreators } from "../../store/ducks/has_active_events";
import {
  Container,
  ProductContainer,
  HeaderText,
  MapContainer,
  Content,
  MapAndTreeMapContainer,
  DetailContainer,
  HeaderContainer,
  TreeMapContainer,
  TreeMapHeader,
  HeaderTableContainer,
  SettingsIconContainer,
  TreeMapDimensions,
  HeaderTreeMapContainer,
} from "./styles";
import Map from "../Map/Map";
import Table from "../Table";
import TreeMap from "../TreeMap";
import ChipGroup from "../ChipGroup";
import SettingsIcon from "react-feather/dist/icons/settings";
import TreeMapDialog from "../TreeMapDialog";
import DownloadFile from "../DownloadFile";
import { getFeatures } from "../../utils/features";
import ProductFilter from "../ProductFilter";
import RecProduct from "../RecProduct";
import { Mixpanel } from "../../utils/mixpanel";
import {
  RECSYS,
  HISTORIC,
  PRODUCT_DETAIL_DOWNLOAD,
  PRODUCT_CHIP,
  NIVEL1,
  SIMILARITY,
  PRODUCT,
  CSV
} from "../../constants";
import RecEventDialog from "../RecEventDialog";
import RecProductSimilarity from "../RecProductSimilarity";
import { getFilterRules } from "../../utils";
import IconButton from '../IconButton';
import ExportIcon from "react-feather/dist/icons/external-link";

const CONTEXT = PRODUCT;

class Product extends Component {

  state = {
    isOpenedDialog: false,
    exportType: CSV
  };

  handleOpenDialog = () => {
    this.setState({ isOpenedDialog: !this.state.isOpenedDialog })
  };

  handleExportType = (exportType) => {this.setState({exportType: exportType});}

  componentDidMount() {
    const { productid, product } = this.props.match.params;

    this.props.mapResetData()
    this.props.treeMapProductInit();
    this.props.productDetailInit();
    this.props.getTenantConfig();
    this.props.setTitle("Produto");

    if (productid && product) {
      this.props.setChip(CONTEXT, RECSYS);
      return;
    }

    Mixpanel.track("INIT_PRODUCT");
  }

  renderHistoric = () => {
    const { data, defaultData, sort, expandedRow, loading, error } =
      this.props.product_detail;
    const { role } = this.props.tenant;

    return (
      <DetailContainer>
        <ProductContainer>
          <HeaderTableContainer>
            <HeaderText>Detalhamento de Produtos</HeaderText>
            {role === NIVEL1 || data.items === undefined ? null : (
              <div>
                <DownloadFile
                  label='Baixar lista'
                  mixpanelTrackEvent={PRODUCT_DETAIL_DOWNLOAD}
                  filename="detalhamento_de_produtos"
                  header={data.header}
                  rows={data.items}
                  isDialogOpened={this.state.isOpenedDialog}
                  handleCloseDialog={() => this.setState({ isOpenedDialog: false })}
                  onSelectExportType={this.handleExportType}
                />
                <IconButton
                  mixpanelTrackEvent={this.props.mixpanelTrackEvent}
                  onClick={() => this.handleOpenDialog()}
                  label={'Exportar'}
                  icon={<ExportIcon />}
                />
              </div>
            )}
          </HeaderTableContainer>
          <Table
            data={data}
            sort={sort}
            error={error}
            hasDetail={true}
            loading={loading}
            defaultData={defaultData}
            expandedRow={expandedRow}
            sortBy={this.props.productDetailSortBy}
            expand={this.props.productDetailExpand}
          />
        </ProductContainer>

        <MapAndTreeMapContainer>
          <TreeMapContainer>{this.renderTreemap({})}</TreeMapContainer>

          <MapContainer>
            <HeaderContainer>
              <HeaderText>Distribuição de faturamento</HeaderText>
            </HeaderContainer>
            <Map />
          </MapContainer>
        </MapAndTreeMapContainer>
      </DetailContainer>
    );
  };

  renderTreemap = ({ height }) => {
    const { setDialog } = this.props;
    const { filter } = this.props.filter;
    const isClickEnabled = Object.keys(filter).length;
    const { parent, child, data, loading } = this.props.treemap_product;

    return (
      <Fragment>
        <HeaderTreeMapContainer>
          <TreeMapHeader>Tree Map</TreeMapHeader>

          <SettingsIconContainer>
            {parent && child && Object.keys(data).length ? (
              <TreeMapDimensions>
                <b>Grupos</b>: {parent.label}, {child.label} x <b>Cores:</b>{" "}
                {parent.label}
              </TreeMapDimensions>
            ) : null}
            {isClickEnabled ? (
              <SettingsIcon
                size={18}
                onClick={!loading ? setDialog.bind(this, "treemap") : null}
              />
            ) : null}
          </SettingsIconContainer>
        </HeaderTreeMapContainer>
        <TreeMap height={height} context={CONTEXT} />
      </Fragment>
    );
  };

  onChangeChip = ({ activeChip }) => {
    const filter = { context: CONTEXT, chip: activeChip }
    const filterRules = getFilterRules(this.props, CONTEXT, activeChip)

    if (!filterRules || !filterRules.length) {
      return;
    }

    filterRules.forEach((fun) => {
      fun(filter);
    });
  };

  render() {
    const { chip, product_detail, rec_product, rec_product_similarity, match } =
      this.props;
    const { tenant, role } = this.props.tenant;
    const chipOptions = getFeatures(tenant, PRODUCT_CHIP) || [];

    return (
      <>
        <Container>
          <ProductFilter context={CONTEXT} title="Produto" match={match} />

          <Content>
            <ChipGroup
              onChange={(activeChip) => this.onChangeChip({ activeChip })}
              isDisabled={
                product_detail.loading ||
                rec_product.loading ||
                rec_product_similarity.loading
              }
              data={chipOptions}
              title=""
              context={CONTEXT}
            />

            {chip.product === HISTORIC ? this.renderHistoric() : null}
            {chip.product === RECSYS ? (
              <RecProduct history={this.props.history} role={role} />
            ) : null}
            {chip.product === SIMILARITY ? (
              <RecProductSimilarity history={this.props.history} role={role} />
            ) : null}
          </Content>
        </Container>
        <TreeMapDialog context={CONTEXT} />
        <RecEventDialog role={role} />
      </>
    );
  }
}

const mapStateToProps = ({
  title,
  product_detail,
  dialog,
  chip,
  filter,
  treemap_product,
  tenant,
  rec_product,
  tenant_config,
  product,
  app_product,
  rec_product_similarity,
  regional_unit,
  rec_product_indicators,
}) => {
  return {
    title,
    product_detail,
    dialog,
    chip,
    filter,
    treemap_product,
    tenant,
    rec_product,
    tenant_config,
    product,
    app_product,
    rec_product_similarity,
    regional_unit,
    rec_product_indicators,
  };
};

export default compose(
  connect(mapStateToProps, {
    ...TitleCreators,
    ...ProductDetailCreators,
    ...DialogCreators,
    ...CustomerDetailCreators,
    ...MapCreators,
    ...RecProductCreators,
    ...FilterCreators,
    ...ChipCreators,
    ...TreemapProductCreators,
    ...TenantConfigCreators,
    ...RecProductSimilarityCreators,
    ...RecProductIndicatorsCreators,
    ...ProductCreators,
    ...RegionalUnitCreators,
    ...CityCreators,
    ...SectorCreators,
    ...PortCreators,
    ...InstrumentCreators,
    ...YearCreators,
    ...ContactTypeCreators,
    ...ThemeCreators,
    ...HasActiveEventsCreators,
  })
)(Product);
