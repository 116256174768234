import api from '../../services/api';
import { Creators } from '../ducks/has_active_events';
import { call, put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';
import { captureSentryException } from '../../utils';

export function* getHasActiveEvents({ filter }) {
  try {
    let response; 

    yield put(Creators.hasActiveEventsRequest());
    response = yield call(api.post, 'has-active-events', filter);

    yield put(Creators.hasActiveEventsSuccess(filter.chip, response.data));
  } catch (err) {
    yield captureSentryException(err, filter);
    yield put(Creators.hasActiveEventsError({ err }));
    yield put(toastrActions.add({
      type: 'error',
      title: 'Erro',
      message: 'Falha ao buscar Disponibilidade de eventos'
    }));
  }
} 